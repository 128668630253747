body {
  font-family: "Lato", sans-serif;
  margin: 0;
  background-color: #eee;
}
#nprogress .bar {
  background: #d4101a !important;
  height: 5px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #d4101a, 0 0 5px #000 !important;
}

#nprogress .spinner-icon {
  border-top-color: #d4101a !important;
  border-left-color: #d4101a !important;
}
a {
  color: inherit;
  text-decoration: none;
}
h4 {
  line-height: 1.5em;
}
.aAsHyper {
  text-decoration: underline;
}
.dblock {
  position: relative !important;
}
.aTag {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.pvtAxisContainer,
.pvtVals {
  border: 1px solid #555 !important;
  background: #fff !important;
}
.pvtAxisContainer li span.pvtAttr {
  border: 1px solid #555 !important;
  background: #fff !important;
}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  font-size: 9pt;
  border: 1px solid #555 !important;
  background: #fff !important;
}
table.pvtTable tbody tr td {
  color: #003966 !important;
  font-size: 9pt;
  border: 1px solid #555 !important;
}
.pvtAxisContainer li span.pvtAttr {
  font-family: "Lato", sans-serif;
}
.pvtUi {
  font-family: "Lato", sans-serif !important;
  color: #000 !important;
}
table.pvtTable * {
  font-family: "Lato", sans-serif;
}
.fullWidth {
  width: 100%;
  margin-top: 10px !important;
}
